import { useAppDispatch } from "@/store";
import endpoints from "@/api/endpoints";
import httpClient from "@/api/http-client";
import { Product } from "@/models/category";
import { authActions, selectAuth } from "@/store/slices/auth";
import { fetchCartItems, selectCart } from "@/store/slices/cart";
import styles from "@/styles/product_list.module.scss";
import Tippy from "@tippyjs/react";
import Image from "next/image";
import Link from "next/link";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import fast from "../../assets/images/fast.png";
import CurrencyInr from "../common/currency-inr";
import LoadingSpinner from "../common/loading-spinner";
import LoadingSpinnerSmall from "../common/loading-spinner-small";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart as faHeartSolid } from "@fortawesome/free-solid-svg-icons";
import { faHeart as faHeartRegular } from "@fortawesome/free-regular-svg-icons";
import { fetchWishlistItems, selectWishlist } from "@/store/slices/wishlist";
import { fbqTrackEvent } from "@/lib/fpixel";
import { initTryOnEventListener } from "@/lib/tryOnTracking";
import { syteTrackEvent } from "@/lib/syte";
import { S3ImageLoader } from "@/image-loaders/s3-image-loader";
import { unbxdTrackEvent } from "@/lib/unbxd";

type ProductCardPropsType = {
  product: Product;
  unbxd?: any;
};

const ProductCard = ({ product, unbxd }: ProductCardPropsType) => {
  const dispatch = useAppDispatch();
  const [cartAddLoading, setCartAddLoading] = useState(false);
  const [cartRemoveLoading, setCartRemoveLoading] = useState(false);
  const [wishlistActionLoading, setwishlistActionLoading] = useState(false);
  const cartState = useSelector(selectCart);
  const [actionData, setActionData] = useState({ type: "", id: "" });
  const wishlistState = useSelector(selectWishlist);
  const authState = useSelector(selectAuth);
  const actionInProgress = useRef(false);
  const [tryOnAddToCart, setTryOnAddToCart] = useState(false);
  const [tryOnRemoveFromCart, setTryOnRemoveFromCart] = useState(false);

  const cartItem = useMemo(
    () =>
      cartState.items.find((v) => v.identifier == product.priceId.toString()) ||
      null,
    [cartState.items, product.priceId]
  );

  const wishlistItem = useMemo(
    () => wishlistState.items.find((v) => v.id == product.id) || null,
    [wishlistState.items, product.id]
  );

  const addToCart = async () => {
    if (!authState.user) {
      dispatch(authActions.setIsModalOpen(true));
      setActionData({ type: "cart", id: `${product?.priceId}` });
      sessionStorage.setItem(
        "add-to-cart-or-wishlist-id",
        `${product?.priceId}`
      );
      return;
    }
    setCartAddLoading(true);
    try {
      await httpClient.post(endpoints.cart.add(), {
        is_solitaire: false,
        type: "product",
        identifier: product.priceId,
      });
      await dispatch(fetchCartItems());
      if (unbxd?.isunbxd) {
        const payload = {
          pid: product.sku,
          variantId: product.priceId,
          qty: "1",
          price: product.discount ? product.discount.price : product.price,
        };

        unbxdTrackEvent("addToCart", payload);
      }
      if (window.Moengage) {
        window.Moengage.track_event("Added to Cart", {
          name: product.title,
          id: product.sku,
          amount: product.discount ? product.discount.total : product.price,
        });
      }

      toast("Item added in cart", {
        type: "success",
        toastId: "cart-add-success",
      });
      fbqTrackEvent("AddToCart", {
        content_type: "product",
        content_ids: [product.sku],
      });
      if (product.categories && product.categories.length) {
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: "add_to_cart",
          ecommerce: {
            currency: "INR",
            value: product.discount ? product.discount.price : product.price,
            items: [
              {
                item_id: product.sku,
                item_name: product.title,
                item_category: [...product.categories].reverse()[0].name,
                price: product.discount
                  ? product.discount.price
                  : product.price,
                quantity: 1,
              },
            ],
          },
        });
      }
      syteTrackEvent({
        name: "products_added_to_cart",
        tag: "ecommerce",
        osh_id: authState.user?.uuid,
        products: [
          {
            sku: product.sku,
            price: product.discount ? product.discount.price : product.price,
            quantity: 1,
          },
        ],
      });
    } catch (error) {}
    setCartAddLoading(false);
  };

  const clickProduct = async () => {
    if (unbxd?.isunbxd) {
      const payload = {
        pid: product.sku,
        requestId: unbxd?.requestId,
        experience_pagetype: unbxd?.experience_pagetype,
        experience_widget: unbxd?.experience_widget,
      };
      unbxdTrackEvent("click", payload);
      unbxdTrackEvent("product_view", payload);
    }
  };

  useEffect(() => {
    if (tryOnAddToCart) {
      setTryOnAddToCart(false);
      addToCart();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tryOnAddToCart]);

  const removeFromCart = async (requireConfirmation = true) => {
    if (requireConfirmation) {
      if (!confirm("Are you sure you want to remove this product from cart?"))
        return;
    }
    setCartRemoveLoading(true);
    try {
      await httpClient.delete(endpoints.cart.remove(cartItem?.id));
      await dispatch(fetchCartItems());
      if (unbxd?.isunbxd) {
        const payload = {
          pid: product.sku,
          variantId: product.priceId,
          qty: "1",
          price: product.discount ? product.discount.price : product.price,
        };

        unbxdTrackEvent("cartRemoval", payload);
      }

      if (window.Moengage) {
        window.Moengage.track_event("Removed From Cart", {
          name: product.title,
          id: product.sku,
          amount: product.discount ? product.discount.total : product.price,
        });
      }

      toast("Item removed from cart", {
        type: "info",
        toastId: "cart-remove-success",
      });
      if (product.categories && product.categories.length) {
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: "remove_from_cart",
          ecommerce: {
            currency: "INR",
            value: product.discount ? product.discount.price : product.price,
            items: [
              {
                item_id: product.sku,
                item_name: product.title,
                item_category: [...product.categories].reverse()[0].name,
                price: product.discount
                  ? product.discount.price
                  : product.price,
                quantity: 1,
              },
            ],
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
    setCartRemoveLoading(false);
  };

  useEffect(() => {
    if (tryOnRemoveFromCart) {
      setTryOnRemoveFromCart(false);
      removeFromCart(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tryOnRemoveFromCart]);

  const openTryOn = () => {
    if (window.initMirrarUI && process.env.NEXT_PUBLIC_STYLEDOTME_BRAND_ID) {
      initTryOnEventListener((name, data) => {
        if (name == "add-to-cart") {
          if (data.productCode == product.sku) {
            setTryOnAddToCart(true);
          }
        }
        if (name == "ProductRemoveFromCart") {
          if (data.productCode == product.sku) {
            setTryOnRemoveFromCart(true);
          }
        }
      });
      window.initMirrarUI(product.sku, {
        brandId: process.env.NEXT_PUBLIC_STYLEDOTME_BRAND_ID,
      });
      if (product.categories && product.categories.length) {
        window.gtag("event", "tryon-button-click", {
          event_category: "mirrar-internal-event",
          event_label: `${[...product.categories].reverse()[0].name}/${
            product.sku
          }/${product.discount ? product.discount.total : product.price}`,
        });
      }
    }
  };

  const addToWishlist = async () => {
    if (wishlistActionLoading) return;
    if (!authState.user) {
      dispatch(authActions.setIsModalOpen(true));
      sessionStorage.setItem("add-to-cart-or-wishlist-id", `${product?.id}`);
      setActionData({
        type: "wishlist",
        id: `${product?.id}`,
      });
      return;
    }
    setwishlistActionLoading(true);
    try {
      await httpClient.post(endpoints.wishlist.add(), {
        product_id: product.id,
        user_id: authState.user.id,
      });
      await dispatch(fetchWishlistItems(authState.user.id as any));
      toast("Item added in wishlist", {
        type: "success",
        toastId: "withlist-add-success",
      });
      fbqTrackEvent("AddToWishlist", {
        content_type: "product",
        content_ids: [product.sku],
      });
    } catch (error) {}
    setwishlistActionLoading(false);
  };

  const removeFromWishlist = async () => {
    if (wishlistActionLoading) return;
    if (!confirm("Are you sure you want to remove this product from wishlist?"))
      return;
    if (!authState.user) {
      dispatch(authActions.setIsModalOpen(true));
      return;
    }
    setwishlistActionLoading(true);
    try {
      await httpClient.post(endpoints.wishlist.remove(), {
        product_id: product.id,
        user_id: authState.user.id,
      });
      await dispatch(fetchWishlistItems(authState.user.id as any));
      toast("Item removed from wishlist", {
        type: "success",
        toastId: "withlist-remove-success",
      });
    } catch (error) {}
    setwishlistActionLoading(false);
  };

  useEffect(() => {
    const handleAction = async () => {
      if (actionInProgress.current) return;

      if (actionData.id && authState.user) {
        actionInProgress.current = true;

        try {
          if (actionData.type === "cart") {
            await addToCart();
          } else if (actionData.type === "wishlist") {
            await addToWishlist();
          }
        } catch (error) {
          console.error("Error handling action:", error);
        } finally {
          setActionData({ type: "", id: "" });
          sessionStorage.removeItem("add-to-cart-or-wishlist-id");
          actionInProgress.current = false;
        }
      }
    };
    const cart_id = sessionStorage.getItem("add-to-cart-or-wishlist-id");
    if (`${actionData?.id}` === cart_id && authState.user) {
      handleAction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState, actionData]);

  return (
    <div
      className={`${styles.product} product-info-sku-variant`}
      data-sku={product.sku}
      data-variant-id={product.priceId}
    >
      <div className={styles.pad_10}>
        <div className={styles.products_text_top}>
          <div className={styles.product_top_left}>
            {!!product.new_arrival && (
              <div className={styles.new_arrival}>NEW ARRIVAL</div>
            )}
            {!!product.best_seller && (
              <div className={styles.best_seller}>BESTSELLER</div>
            )}
            {!!product.trending && (
              <div className={styles.trending}>TRENDING</div>
            )}
            {!!product.deal_of_the_day && (
              <div className={styles.trending}>Deal of The Day</div>
            )}
            {!!product.discount && (
              <>
                <div className={styles.discount}>{product.discount?.name}</div>
                {/* <div className={styles.text}>
                  ON {product.discount?.name?.split("On")[1].toUpperCase()}
                </div> */}
              </>
            )}
          </div>
          <div className={styles.cart_top}>
            {!!product.try_online && (
              <div className={styles.tryontext} onClick={openTryOn}>
                <span>TryOn</span>
              </div>
            )}
            {product.fastDelivery && (
              <Tippy content="Fast Delivery">
                <div className={styles.fast_delivery}>
                  <Image src={fast} alt="Fast Delivery" priority unoptimized />
                </div>
              </Tippy>
            )}
            {wishlistActionLoading && (
              <span style={{ position: "relative", top: -6 }}>
                <LoadingSpinnerSmall />
              </span>
            )}
            {!wishlistActionLoading && (
              <>
                {wishlistItem ? (
                  <Tippy content="Wishlisted">
                    <a className="pointer" onClick={removeFromWishlist}>
                      <FontAwesomeIcon
                        icon={faHeartSolid}
                        width={20}
                        color={"#FF1933"}
                        style={{ position: "relative", top: -8 }}
                      />
                    </a>
                  </Tippy>
                ) : (
                  <Tippy content="Add To Wishlist">
                    <a className="pointer" onClick={addToWishlist}>
                      <FontAwesomeIcon
                        icon={faHeartRegular}
                        width={20}
                        color={"#FF1933"}
                        style={{ position: "relative", top: -8 }}
                      />
                    </a>
                  </Tippy>
                )}
              </>
            )}
          </div>
        </div>
        <div onClick={() => clickProduct()}>
          <Link href={"/jewellery/" + product.slug} passHref>
            <a target="_blank" rel="noopener noreferrer">
              <div className={styles.product_image}>
                <Image
                  src={product.image}
                  className={styles.img_fluid}
                  alt={product.title}
                  width={2500}
                  height={2500}
                  sizes="(max-width: 479px) 148px, (max-width: 640px) 200px, (max-width: 991px) 318px, (max-width: 1199px) 200px, (max-width: 1600px) 245px, (max-width: 1920px) 50vw, 100vw"
                  loader={S3ImageLoader}
                />
              </div>
            </a>
          </Link>
        </div>
        <p className={styles.product_text_d}>{product.title}</p>
        <div className={styles.price_cart}>
          <div className={styles.price1}>
            <span>
              <CurrencyInr
                value={
                  product.discount?.total
                    ? product.discount.total
                    : product.price
                }
              />
            </span>
            <br />
            {product.discount?.total && (
              <div className={styles.price2}>
                <span>
                  <CurrencyInr value={product.price} />
                </span>
              </div>
            )}
          </div>

          {!cartItem && (
            <>
              {cartAddLoading && <LoadingSpinner />}
              {!cartAddLoading && (
                <Tippy content="Add To Cart">
                  <div
                    className={`${styles.details} pointer`}
                    onClick={addToCart}
                  >
                    <i className="bi bi-cart-plus"></i>
                  </div>
                </Tippy>
              )}
            </>
          )}

          {cartItem && (
            <>
              {cartRemoveLoading && <LoadingSpinner />}
              {!cartRemoveLoading && (
                <Tippy content="Remove From Cart">
                  <div
                    className={`${styles.details} pointer`}
                    onClick={() => removeFromCart()}
                  >
                    <i className="bi bi-cart-dash-fill"></i>
                  </div>
                </Tippy>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
